import React, { useEffect, useMemo, useState } from 'react';
import { useForm, useWatch } from 'react-hook-form';
import { useDispatch, useSelector } from 'react-redux';
import { loading } from '@beewise/react-utils';
import PropTypes from 'prop-types';
import Button from '@beewise/button';
import { CheckboxFormField, InputFormField, ROOT_ERRORS, getRootLevelErrors } from '@beewise/hook-form';
import SelectGridField from 'components/reusables/SelectGridField';
import readJsonFile from 'utils/readJsonFile';
import { fetchBulkUpdateSettings, fetchConnectivityMap } from '../actions';
import { gridFullOptions, getFullBhomeOptions, ALL_VERSIONS } from './utils';
import { FETCH_BULK_UPDATE_SETTINGS } from '../actionTypes';

const defaultValues = {
    manualBhomeIds: '',
    pickedBhomes: [],
    key: '',
    shouldOverride: false,
    enforceImmediateAction: false,
    settings: '',
    file: null,
};

const BulkBhomeSettingsUpdate = ({ bhomes, isSuperViewer }) => {
    const {
        control,
        reset,
        handleSubmit,
        setValue,
        setError,
        formState: { errors },
        resetField,
    } = useForm({ defaultValues });

    const dispatch = useDispatch();
    const connectivityMap = useSelector(state => state.dashboard.connectivityMap);
    const [selectedBhomeIds, setSelectedBhomeIds] = useState([]);

    useEffect(() => {
        dispatch(fetchConnectivityMap());
    }, [dispatch]);

    const bhomeOptions = useMemo(
        () => getFullBhomeOptions({ bhomes, bhomeVersion: ALL_VERSIONS, connectivityMap }),
        [bhomes, connectivityMap]
    );

    const handleFileUpload = async (files, e) => {
        if (files) {
            try {
                const result = await readJsonFile(files);
                setValue('settings', JSON.stringify(result), { shouldDirty: true });
            } catch (e) {
                setValue('settings', '', { shouldDirty: true });
                setError(ROOT_ERRORS.FORM_ERROR, { type: 'validation', message: e });
            } finally {
                e.target.value = null;
            }
        }
    };

    const handleUpload = ({ pickedBhomes, settings, key, shouldOverride, enforceImmediateAction }) => {
        if (settings && pickedBhomes.length) {
            dispatch(
                fetchBulkUpdateSettings(pickedBhomes, settings, key.trim(), shouldOverride, enforceImmediateAction, {
                    resolver: () => {
                        reset({
                            ...defaultValues,
                            pickedBhomes,
                        });
                    },
                    toastText: <div className="scan-toast">Success!</div>,
                    toastOptions: {
                        position: 'top-center',
                        className: 'beewise-toast action-toast toast-success',
                    },
                })
            );
        }
    };
    const rootErrors = getRootLevelErrors(errors);

    const resetSettings = () => resetField('settings', { shouldDirty: true });
    const validateAndSetBhomeIds = enteredIds => {
        const validIds = enteredIds.split(' ').filter(id => bhomes.some(bhome => Number(bhome.id) === Number(id)));
        setValue('manualBhomeIds', enteredIds);
        setSelectedBhomeIds(validIds);
        setValue(
            'pickedBhomes',
            validIds.map(id => Number(id))
        );
    };

    const [settings, key] = useWatch({
        name: ['settings', 'key'],
        control,
    });

    const keyIncludesUi = key?.includes('ui.');

    return (
        <div className="bulk-update-settings">
            <InputFormField
                name="manualBhomeIds"
                control={control}
                label="Enter Beehome IDs"
                placeholder="e.g., 1 2 3"
                onChange={validateAndSetBhomeIds}
            />
            <SelectGridField
                name="pickedBhomes"
                gridName="Pick Beehome(s)"
                isMulti
                gridOptions={gridFullOptions}
                label="Pick Beehome(s)"
                options={bhomeOptions}
                control={control}
                required
                size="extra-large"
                selectedBhomeIds={selectedBhomeIds}
            />
            <InputFormField
                name="key"
                control={control}
                onChange={resetSettings}
                label="Optional dot separeted Setting key (e.g. camera_settings.rate.a)"
            />
            {keyIncludesUi && (
                <div className="cloud-config-alert">
                    Changes inside the &#39;UI&#39; key will have no effect. Please use Bhome Cloud Config Tab if you
                    need to change it.
                </div>
            )}
            <div className="bulk-update-settings-item">
                <CheckboxFormField name="shouldOverride" control={control} label="Override value" />
                <CheckboxFormField name="enforceImmediateAction" control={control} label="Enforce immediate" />
            </div>
            <InputFormField name="settings" control={control} label="Enter valid settings json" required={!settings} />
            <InputFormField name="file" type="file" accept=".json" onChange={handleFileUpload} control={control} />
            {!isSuperViewer && (
                <Button onClick={handleSubmit(handleUpload)} className="btn-primary">
                    Save
                </Button>
            )}
            {rootErrors && <div className="error">{rootErrors}</div>}
        </div>
    );
};

BulkBhomeSettingsUpdate.propTypes = {
    bhomes: PropTypes.arrayOf(PropTypes.shape()),
    isSuperViewer: PropTypes.bool,
};

export default loading(FETCH_BULK_UPDATE_SETTINGS.default)(BulkBhomeSettingsUpdate);
