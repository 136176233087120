import { FETCH_SETTINGS, FETCH_UPDATE_SETTING, FETCH_ALL_SETTINGS } from '../actionTypes';

export const fetchSettings = () => ({
    type: 'FETCH',
    params: {
        type: FETCH_SETTINGS.default,
        url: '/settings/get-all-by-types?types[]=default_bhome&types[]=default_cloud_config',
    },
});
export const fetchAllSettings = () => ({
    type: 'FETCH',
    params: {
        type: FETCH_ALL_SETTINGS.default,
        url: '/settings/get-all',
    },
});

export const fetchUpdateSetting = (id, setting) => ({
    type: 'FETCH',
    params: {
        type: FETCH_UPDATE_SETTING.default,
        url: `/settings/${id}`,
        method: 'PUT',
        body: {
            setting,
        },
    },
});
