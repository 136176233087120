import React, { forwardRef, useState } from 'react';
import PropTypes from 'prop-types';
import cx from 'classnames';
import Icon from '@beewise/icon';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faAngleDown } from '@fortawesome/free-solid-svg-icons';
import './Button.scss';

const ButtonComponent = forwardRef(
  (
    {
      iconType,
      className,
      iconClassName,
      children,
      shadow = false,
      hasDropdown = false,
      onClick,
      menuItems,
      ...props
    },
    ref
  ) => {
    const [isDropdownOpen, setIsDropdownOpen] = useState(false);

    const handleButtonClick = (e) => {
      if (hasDropdown) {
        setIsDropdownOpen(!isDropdownOpen);
      }
      onClick?.(e);
    };

    const buttonComponent = (
      <button
        className={cx('btn', {
          [className]: !!className,
          shadow: !!shadow
        })}
        type="button"
        ref={ref}
        onClick={handleButtonClick}
        {...props}
      >
        {!!iconType && (
          <Icon
            type={iconType}
            className={cx('button-icon', {
              [iconClassName]: !!iconClassName
            })}
          />
        )}
        {children && <span className="button-text">{children}</span>}
        {hasDropdown && <FontAwesomeIcon icon={faAngleDown} className="dropdown-icon" />}
      </button>
    );

    if (!hasDropdown) {
      return buttonComponent;
    }

    return (
      <div className="btn-container">
        {buttonComponent}
        {hasDropdown && isDropdownOpen && (
          <div className="dropdown-menu">
            {!!menuItems?.length &&
              menuItems.map(({ label, description, onClick }, index) => (
                <span
                  key={`dropdown-item-${index}`}
                  className="dropdown-item"
                  onClick={() => {
                    onClick?.();
                    setIsDropdownOpen(false);
                  }}
                  role="button"
                  tabIndex="0"
                >
                  <h3 className="label">{label}</h3>
                  {description && (
                    <p
                      className={cx('description', {
                        large: description?.length > 50
                      })}
                    >
                      {description}
                    </p>
                  )}
                </span>
              ))}
          </div>
        )}
      </div>
    );
  }
);

ButtonComponent.propTypes = {
  iconType: PropTypes.string,
  shadow: PropTypes.bool,
  className: PropTypes.string,
  iconClassName: PropTypes.string,
  children: PropTypes.node,
  onClick: PropTypes.func,
  menuItems: PropTypes.arrayOf(
    PropTypes.shape({
      label: PropTypes.string.isRequired,
      description: PropTypes.string,
      onClick: PropTypes.func.isRequired
    })
  ),
  hasDropdown: PropTypes.bool
};

export default ButtonComponent;
