import React from 'react';
import { components } from 'react-select';
import PropTypes from 'prop-types';
import Checkbox from '@beewise/checkbox-field';

const CheckboxOption = ({ isCheckboxEnabled, selectOption, data, isSelected, ...props }) => {
  const handleChange = (event) => {
    const isChecked = event.target.checked;

    if (isChecked) {
      selectOption(data);
    } else {
      selectOption({ ...data, isSelected: false });
    }
  };

  return (
    <components.Option {...props}>
      {isCheckboxEnabled ? (
        <Checkbox label={data.label} checked={isSelected} onChange={handleChange} />
      ) : (
        <label>{data.label}</label>
      )}
    </components.Option>
  );
};

CheckboxOption.propTypes = {
  data: PropTypes.shape(),
  selectOption: PropTypes.func,
  isCheckboxEnabled: PropTypes.bool,
  isSelected: PropTypes.bool
};

export default CheckboxOption;
