import React, { forwardRef } from 'react';
import PropTypes from 'prop-types';

const Place = forwardRef(({ color = 'currentColor', width = 20, height = 20, ...rest }, ref) => (
  <svg
    ref={ref}
    xmlns="http://www.w3.org/2000/svg"
    width={width}
    height={height}
    viewBox={`0 0 ${width} ${height}`}
    fill="none"
    {...rest}
  >
    <path
      d="M9.91304 7.86957C11.2577 7.86957 12.3478 6.77948 12.3478 5.43478C12.3478 4.09009 11.2577 3 9.91304 3C8.56835 3 7.47826 4.09009 7.47826 5.43478C7.47826 6.77948 8.56835 7.86957 9.91304 7.86957ZM9.91304 7.86957V13.9565M12.9565 11.1466C15.8159 11.6032 17.8261 12.6904 17.8261 13.9565C17.8261 15.6373 14.2835 17 9.91304 17C5.54261 17 2 15.6373 2 13.9565C2 12.6904 4.01022 11.6047 6.86957 11.1466"
      stroke={color}
      strokeLinecap="round"
      strokeLinejoin="round"
    />
  </svg>
));

Place.propTypes = {
  color: PropTypes.string,
  width: PropTypes.number,
  height: PropTypes.number
};

Place.displayName = 'Place';

export default Place;
