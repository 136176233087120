import React, { useMemo } from 'react';
import PropTypes from 'prop-types';
import TextField from '@beewise/text-field';
import { isEqual } from 'lodash-es';
import { JsonEditor as Editor } from 'components/reusables/JsonEditor';
import ReactDiffViewer, { DiffMethod } from 'react-diff-viewer-continued';
import Button from '@beewise/button';
import Checkbox from '@beewise/checkbox-field';

const BhomeSettings = ({
    currentSettings,
    handleSettingsChange,
    fooKey,
    handleSaveSettings,
    isSubmitDisabled,
    toggleHashCheck,
    disableHashCheck,
    isTextMode,
    toggleMode,
    handleDownload,
    enforceImmediateAction,
    toggleEnforceImmediateAction,
    isCloudConfig,
    currentCloudConfig,
    remoteCloudConfig,
}) => {
    const areSettingsSame = useMemo(() => {
        if (isCloudConfig) {
            return isEqual(remoteCloudConfig?.config, currentCloudConfig?.config);
        }
        return isEqual(currentSettings?.data?.settings, currentSettings?.machine_data?.settings);
    }, [currentCloudConfig, currentSettings, isCloudConfig, remoteCloudConfig]);

    const oldValue = useMemo(
        () =>
            JSON.stringify(
                isCloudConfig ? remoteCloudConfig?.config : currentSettings?.machine_data?.settings,
                null,
                4
            ),
        [currentSettings, isCloudConfig, remoteCloudConfig]
    );
    const newValue = useMemo(
        () => JSON.stringify(isCloudConfig ? currentCloudConfig?.config : currentSettings?.data?.settings, null, 4),
        [currentCloudConfig, currentSettings, isCloudConfig]
    );

    const itemToManipulate = isCloudConfig ? currentCloudConfig : currentSettings;

    const hasUiSettings = itemToManipulate?.data?.settings?.ui;

    return (
        <>
            <div className="config-content">
                <TextField label="Bhome ID" value={itemToManipulate?.bhome_id} disabled size="small" />
                {!isCloudConfig && (
                    <TextField
                        className="config-comment"
                        label="Comment"
                        value={currentSettings.comment}
                        onChange={value => handleSettingsChange({ comment: value })}
                        size="small"
                    />
                )}
                {hasUiSettings && !isCloudConfig && (
                    <div className="cloud-config-alert">
                        Changes inside the &#39;UI&#39; key will have no effect. Please use Bhome Cloud Config Tab if
                        you need to change it.
                    </div>
                )}
                <div key={`${fooKey}-editor`}>
                    <div className="config-header">
                        <Checkbox
                            className="config-mode-switch"
                            label="Text Mode"
                            checked={isTextMode}
                            onChange={toggleMode}
                        />
                        <Button className="btn btn-primary" onClick={handleDownload}>
                            Download Config
                        </Button>
                        <div className="config-updater">
                            Last updated:{' '}
                            {currentSettings?.last_updated
                                ? new Date(currentSettings.last_updated).toLocaleString()
                                : 'never'}{' '}
                            (by: {currentSettings.user_email})
                        </div>
                    </div>
                    <Editor
                        mode={isTextMode ? 'text' : 'tree'}
                        value={currentSettings.data.settings}
                        onChange={settings => handleSettingsChange({ settings })}
                    />
                </div>
                <Checkbox
                    className="config-hash-checkbox"
                    onChange={toggleHashCheck}
                    checked={disableHashCheck}
                    label="Disable Hash Check"
                />
                <Checkbox
                    className="config-hash-checkbox"
                    onChange={toggleEnforceImmediateAction}
                    checked={enforceImmediateAction}
                    label="Enforce Immediate"
                />
                <Button disabled={isSubmitDisabled} primary className="btn btn-primary" onClick={handleSaveSettings}>
                    Save
                </Button>
            </div>
            {!areSettingsSame && (
                <div className="config-content">
                    <ReactDiffViewer
                        leftTitle="Machine Config"
                        rightTitle="Cloud config"
                        compareMethod={DiffMethod.LINES}
                        splitView
                        oldValue={oldValue}
                        newValue={newValue}
                    />
                </div>
            )}
        </>
    );
};

BhomeSettings.propTypes = {
    currentSettings: PropTypes.shape(),
    currentCloudConfig: PropTypes.shape(),
    remoteCloudConfig: PropTypes.shape(),
    handleSettingsChange: PropTypes.func,
    toggleHashCheck: PropTypes.func,
    toggleEnforceImmediateAction: PropTypes.func,
    handleSaveSettings: PropTypes.func,
    toggleMode: PropTypes.func,
    handleDownload: PropTypes.func,
    fooKey: PropTypes.number,
    isSubmitDisabled: PropTypes.bool,
    disableHashCheck: PropTypes.bool,
    enforceImmediateAction: PropTypes.bool,
    isTextMode: PropTypes.bool,
    isCloudConfig: PropTypes.bool,
};

export default BhomeSettings;
