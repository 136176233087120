import { ON_UPDATE_CONFIG } from 'actionTypes';
import {
    CLEAR_CREATED_BHOME,
    FETCH_ALL_BHOMES,
    FETCH_CREATE_NEW_BHOME,
    FETCH_SIGNUP,
    GET_ALL_AVAILABLE_BHOMES,
    GET_ALL_COMPANIES,
    GET_USER_ROLES,
    GET_COMPANY_TYPES,
    SET_CURRENT_BHOME,
    FETCH_BHOME_SETTINGS,
    FETCH_SAVE_BHOME_SETTINGS,
    FETCH_BHOME_SETTINGS_HISTORY,
    FETCH_SOFTWARE_BUNDLES,
    FETCH_DELETE_BHOME_BUNDLE,
    FETCH_BHOME_CONNECTIVITY_MAP,
    FETCH_BHOME_CLOUD_CONFIG,
    FETCH_SAVE_BHOME_CLOUD_CONFIG,
} from '../actionTypes';

const dashboard = (
    state = {
        companies: [],
        availableBhomes: [],
        signupError: null,
        bhomes: [],
        createdBhome: null,
        roles: [],
        companyTypes: [],
        currentBhomeId: null,
        currentBhomeSettings: null,
        currentBhomeCloudConfig: null,
        currentBhomeSettingsHistory: [],
        softwareBundles: [],
        connectivityMap: [],
    },
    action
) => {
    switch (action.type) {
        case GET_ALL_COMPANIES.success:
            return {
                ...state,
                companies: action.data.companies,
            };
        case GET_USER_ROLES.success:
            return {
                ...state,
                roles: action.data.roles,
            };
        case GET_COMPANY_TYPES.success:
            return {
                ...state,
                companyTypes: action.data.types,
            };
        case FETCH_ALL_BHOMES.success:
            return {
                ...state,
                bhomes: action.data.bhomes.sort((a, b) => a.id - b.id),
            };
        case GET_ALL_AVAILABLE_BHOMES.success:
            return {
                ...state,
                availableBhomes: action.data.bhomes,
            };
        case FETCH_CREATE_NEW_BHOME.success:
            return {
                ...state,
                createdBhome: action.data.bhome,
                bhomes: [...state.bhomes, action.data.bhome],
                availableBhomes: [...state.availableBhomes, action.data.bhome.id],
            };
        case FETCH_SIGNUP.success:
            return {
                ...state,
                signupError: null,
            };
        case FETCH_SIGNUP.failed:
            return {
                ...state,
                signupError: action.error,
            };
        case CLEAR_CREATED_BHOME:
            return {
                ...state,
                createdBhome: null,
            };
        case SET_CURRENT_BHOME:
            return {
                ...state,
                currentBhomeId: action.bhomeId,
            };
        case FETCH_BHOME_SETTINGS.success:
        case FETCH_SAVE_BHOME_SETTINGS.success:
            return {
                ...state,
                currentBhomeSettings: action.data.settings,
            };
        case FETCH_BHOME_CLOUD_CONFIG.success:
        case FETCH_SAVE_BHOME_CLOUD_CONFIG.success:
            return {
                ...state,
                currentBhomeCloudConfig: action.data.config,
            };
        case FETCH_BHOME_SETTINGS_HISTORY.success:
            return {
                ...state,
                currentBhomeSettingsHistory: action.data.settings,
            };
        case ON_UPDATE_CONFIG:
            return {
                ...state,
                bhomes: state.bhomes.map(bhome => {
                    if (bhome.id === Number(action.payload.bhomeId)) {
                        return {
                            ...bhome,
                            settings: action.payload.settings,
                        };
                    }
                    return bhome;
                }),
                currentBhomeSettings: {
                    ...action.payload.settings,
                },
            };
        case FETCH_SOFTWARE_BUNDLES.success:
            return {
                ...state,
                softwareBundles: action.data.result,
            };
        case FETCH_BHOME_CONNECTIVITY_MAP.success:
            return {
                ...state,
                connectivityMap: action.data?.connectivity,
            };
        case FETCH_DELETE_BHOME_BUNDLE.success: {
            return {
                ...state,
                softwareBundles: state.softwareBundles.filter(bundle => bundle.id !== action.data.id),
            };
        }
        default:
            return state;
    }
};

export default dashboard;
