import React from 'react';
import cn from 'classnames';
import Select, { components } from 'react-select';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faXmark } from '@fortawesome/pro-regular-svg-icons';
import { SelectProps } from './props';
import Error from './Error';
import Label from './Label';
import { SIZES } from './utils';
import useSelectValues from './useSelectValues';
import Menu from './Menu';
import CheckboxOption from './CheckboxOption';

import './SelectField.scss';

const groupStyles = {
  color: '#9E9E9E',
  fontSize: '12px',
  fontWeight: 600,
  lineHeight: '14px',
  textTransform: 'none'
};

const DropdownIndicator = ({ ...props }) => (
  <components.DropdownIndicator {...props}>
    <div className="select-field__indicators" />
  </components.DropdownIndicator>
);

const ClearIndicator = (props) => (
  <components.ClearIndicator {...props}>
    <FontAwesomeIcon icon={faXmark} className="select-field__clear-icon" />
  </components.ClearIndicator>
);

const GroupHeading = (props) => <components.GroupHeading style={groupStyles} {...props} />;

const SelectField = React.forwardRef(
  (
    {
      error,
      name,
      label,
      required,
      value,
      onFocus,
      onBlur,
      size = SIZES.NORMAL,
      components = {},
      className,
      onChange,
      info,
      addNewOption,
      disabled,
      placeholder,
      isCheckboxEnabled = false,
      isMulti = false,
      ...rest
    },
    ref
  ) => {
    const { isActive, handleOnBlur, handleOnFocus, handleOnChange, valueWithLabel } =
      useSelectValues({
        value,
        onChange,
        onFocus,
        onBlur,
        addNewOption,
        ...rest
      });
    const componentExtendedProps = addNewOption ? { Menu } : {};
    const isMultiAndWithCheckbox = !isMulti && !isCheckboxEnabled;

    return (
      <fieldset className={cn('select-field-root', className)}>
        <Select
          {...rest}
          ref={ref}
          value={valueWithLabel || ''}
          onBlur={handleOnBlur}
          onFocus={handleOnFocus}
          className={cn(`select-field select-field--${size}`, { 'select-field--error': error })}
          classNamePrefix="select-field"
          placeholder={label ? '' : placeholder}
          components={{
            IndicatorSeparator: null,
            DropdownIndicator,
            ClearIndicator,
            GroupHeading,
            // eslint-disable-next-line react/no-unstable-nested-components
            Option: (props) => <CheckboxOption {...props} isCheckboxEnabled={isCheckboxEnabled} />,
            ...componentExtendedProps,
            ...components
          }}
          onChange={handleOnChange}
          addNewOption={addNewOption}
          isDisabled={disabled}
          hideSelectedOptions={isMultiAndWithCheckbox}
          closeMenuOnSelect={isMultiAndWithCheckbox}
          isMulti={isMulti}
        />
        <Label
          isActive={isActive}
          error={error}
          label={label}
          name={name}
          required={required}
          size={size}
          value={value}
        />
        <Error info={info} error={error} />
      </fieldset>
    );
  }
);

SelectField.propTypes = SelectProps;

export default SelectField;
