import { useMemo, useState } from 'react';

export default ({ value, onChange, onFocus, onBlur, addNewOption, ...rest }) => {
  const [isActive, setIsActive] = useState(!!value);

  const handleOnFocus = (e) => {
    setIsActive(true);
    if (onFocus) {
      onFocus(e);
    }
  };

  const handleOnBlur = (e) => {
    if (!value) {
      setIsActive(false);
    }
    if (onBlur) {
      onBlur(e);
    }
  };

  const handleOnChange = (e) =>
    Array.isArray(e) ? onChange(e.map((item) => item.value)) : onChange(e?.value);

  const valueWithLabel = useMemo(() => {
    const findOption = (option) => {
      if (Array.isArray(option.options)) {
        return option.options.find((opt) => opt.value === value);
      }
      return option.value === value ? option : undefined;
    };

    if (Array.isArray(value)) {
      return rest.options
        .flatMap((option) => {
          if (Array.isArray(option.options)) {
            return option.options
              .filter((opt) => value.includes(opt.value))
              .map((opt) => ({ label: opt.label, value: opt.value }));
          }
          return value.includes(option.value) ? [{ label: option.label, value: option.value }] : [];
        })
        .filter(Boolean);
    } else {
      const foundOption = rest.options.find(findOption);
      return foundOption ? findOption(foundOption) : undefined;
    }
  }, [value, rest.options]);

  return {
    isActive,
    handleOnFocus,
    handleOnBlur,
    handleOnChange,
    valueWithLabel,
    addNewOption
  };
};
