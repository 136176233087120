import React, { useMemo } from 'react';
import PropTypes from 'prop-types';
import TextField from '@beewise/text-field';
import { isEqual } from 'lodash-es';
import { JsonEditor as Editor } from 'components/reusables/JsonEditor';
import ReactDiffViewer, { DiffMethod } from 'react-diff-viewer-continued';
import Button from '@beewise/button';
import Checkbox from '@beewise/checkbox-field';

const BhomeCloudConfig = ({
    handleSettingsChange,
    fooKey,
    handleSaveSettings,
    isSubmitDisabled,
    isTextMode,
    toggleMode,
    handleDownload,
    currentCloudConfig,
    remoteCloudConfig,
}) => {
    const areSettingsSame = useMemo(
        () => isEqual(remoteCloudConfig?.config, currentCloudConfig?.config),
        [currentCloudConfig, remoteCloudConfig]
    );

    const oldValue = useMemo(() => JSON.stringify(remoteCloudConfig?.config, null, 4), [remoteCloudConfig]);
    const newValue = useMemo(() => JSON.stringify(currentCloudConfig?.config, null, 4), [currentCloudConfig]);

    return (
        <>
            <div className="config-content">
                <TextField label="Bhome ID" value={currentCloudConfig?.bhome_id} disabled size="small" />
                <div key={`${fooKey}-editor`}>
                    <div className="config-header">
                        <Checkbox
                            className="config-mode-switch"
                            label="Text Mode"
                            checked={isTextMode}
                            onChange={toggleMode}
                        />
                        <Button className="btn btn-primary" onClick={handleDownload}>
                            Download Config
                        </Button>
                        <div className="config-updater">
                            Last updated:{' '}
                            {currentCloudConfig?.updated_at
                                ? new Date(currentCloudConfig.updated_at).toLocaleString()
                                : 'never'}{' '}
                            (by: {currentCloudConfig.user_email})
                        </div>
                    </div>
                    <Editor
                        mode={isTextMode ? 'text' : 'tree'}
                        value={currentCloudConfig.config}
                        onChange={settings => handleSettingsChange({ settings })}
                    />
                </div>
                <Button disabled={isSubmitDisabled} className="btn btn-primary" onClick={handleSaveSettings}>
                    Save
                </Button>
            </div>
            {!areSettingsSame && (
                <div className="config-content">
                    <ReactDiffViewer
                        leftTitle="Previous Config"
                        rightTitle="New config"
                        compareMethod={DiffMethod.LINES}
                        splitView
                        oldValue={oldValue}
                        newValue={newValue}
                    />
                </div>
            )}
        </>
    );
};

BhomeCloudConfig.propTypes = {
    currentCloudConfig: PropTypes.shape(),
    remoteCloudConfig: PropTypes.shape(),
    handleSettingsChange: PropTypes.func,
    handleSaveSettings: PropTypes.func,
    toggleMode: PropTypes.func,
    handleDownload: PropTypes.func,
    fooKey: PropTypes.number,
    isSubmitDisabled: PropTypes.bool,
    isTextMode: PropTypes.bool,
};

export default BhomeCloudConfig;
