import React, { useState, useEffect, useMemo, useCallback } from 'react';
import PropTypes from 'prop-types';
import { shallowEqual, useDispatch, useSelector } from 'react-redux';
import { SelectField } from '@beewise/select-field';
import { get, isEqual } from 'lodash-es';
import { loading, useToggle } from '@beewise/react-utils';
import {
    fetchBhomeCloudConfig,
    fetchBhomeSettings,
    fetchSaveConfig,
    fetchSaveSettings,
    setCurrentBhome,
} from '../../actions';
import BhomeSettings from './BhomeSettings';
import { FETCH_BHOME_SETTINGS } from '../../actionTypes';
import BhomeCloudConfig from './BhomeCloudConfig';

const BhomeConfig = ({ bhomes, isSuperViewer, isCloudConfig }) => {
    const dispatch = useDispatch();

    // added to hack JSONEditor inner rendering procedure
    const [fooKey, setFooKey] = useState(1);
    const [isFetched, setIsFetched] = useState(false);
    const [disableHashCheck, toggleHashCheck] = useToggle();
    const [enforceImmediateAction, toggleEnforceImmediateAction] = useToggle();
    const [isTextMode, toggleMode] = useToggle();
    const [originalSettings, setOriginalSettings] = useState(null);
    const [originalConfig, setOriginalConfig] = useState(null);
    const [currentSettings, setCurrentSettings] = useState(null);
    const [currentCloudConfig, setCurrentCloudConfig] = useState(null);
    const selectOptions = useMemo(() => bhomes.map(item => ({ label: item.id, value: item.id })), [bhomes]);
    const currentBhomeId = useSelector(state => state.dashboard.currentBhomeId);
    const remoteBhomeSettings = useSelector(state => state.dashboard.currentBhomeSettings, shallowEqual);
    const remoteBhomeCloudConfig = useSelector(state => state.dashboard.currentBhomeCloudConfig, shallowEqual);

    useEffect(() => {
        if (!currentBhomeId) {
            return;
        }

        if (isCloudConfig) {
            dispatch(
                fetchBhomeCloudConfig(currentBhomeId, () => {
                    setIsFetched(true);
                    setTimeout(() => setFooKey(fooKey + 1), 0);
                })
            );
        } else {
            dispatch(
                fetchBhomeSettings(currentBhomeId, () => {
                    setIsFetched(true);
                    setTimeout(() => setFooKey(fooKey + 1), 0);
                })
            );
        }
        // eslint-disable-next-line
    }, [currentBhomeId, dispatch, setFooKey]);

    useEffect(() => {
        setOriginalSettings(remoteBhomeSettings);
        setCurrentSettings(remoteBhomeSettings);
        setFooKey(fooKey + 1);
        // eslint-disable-next-line
    }, [remoteBhomeSettings]);

    useEffect(() => {
        setOriginalConfig(remoteBhomeCloudConfig);
        setCurrentCloudConfig(remoteBhomeCloudConfig);
        setFooKey(fooKey + 1);
        // eslint-disable-next-line
    }, [remoteBhomeCloudConfig]);

    useEffect(() => {
        dispatch(setCurrentBhome(null));
    }, [dispatch, isCloudConfig]);

    useEffect(() => () => dispatch(setCurrentBhome(null)), [dispatch]);

    const handleBhomeSelect = useCallback(
        value => {
            dispatch(setCurrentBhome(value));
        },
        [dispatch]
    );

    const handleToggleMode = useCallback(() => {
        setFooKey(fooKey + 1);
        toggleMode();
    }, [fooKey, toggleMode]);

    const handleSettingsChange = useCallback(
        data => {
            if (isCloudConfig) {
                setCurrentCloudConfig({
                    ...currentCloudConfig,
                    config: {
                        ...currentCloudConfig.config,
                        ...data.settings,
                    },
                });
            } else {
                setCurrentSettings({
                    ...currentSettings,
                    data: {
                        ...currentSettings.data,
                        ...data,
                    },
                });
            }
        },
        [currentCloudConfig, currentSettings, isCloudConfig]
    );

    const handleDownload = useCallback(() => {
        const dataStr = `data:text/json;charset=utf-8,${encodeURIComponent(
            JSON.stringify(isCloudConfig ? currentCloudConfig.config : currentSettings.data.settings)
        )}`;
        const downloadAnchorNode = document.createElement('a');
        downloadAnchorNode.setAttribute('href', dataStr);
        downloadAnchorNode.setAttribute('download', `bhome_${currentBhomeId}_config.json`);
        document.body.appendChild(downloadAnchorNode);
        downloadAnchorNode.click();
        downloadAnchorNode.remove();
    }, [isCloudConfig, currentCloudConfig, currentSettings, currentBhomeId]);

    const handleSaveSettings = useCallback(() => {
        if (isCloudConfig) {
            dispatch(
                fetchSaveConfig({
                    id: currentBhomeId,
                    updateBody: {
                        config: currentCloudConfig.config,
                    },
                    resolver: () => {
                        setFooKey(fooKey + 1);
                    },
                })
            );
        } else {
            dispatch(
                fetchSaveSettings({
                    id: currentBhomeId,
                    settings: {
                        data: currentSettings.data,
                        comment: currentSettings.comment,
                    },
                    previousSettingsData: get(originalSettings, 'data.settings', null),
                    disableHashCheck,
                    enforceImmediateAction,
                    resolver: () => {
                        setFooKey(fooKey + 1);
                    },
                })
            );
        }
    }, [
        isCloudConfig,
        dispatch,
        currentBhomeId,
        currentCloudConfig,
        fooKey,
        currentSettings,
        originalSettings,
        disableHashCheck,
        enforceImmediateAction,
    ]);

    const isSubmitDisabled = useMemo(() => {
        if (isCloudConfig) {
            return isEqual(currentCloudConfig, originalConfig) || isSuperViewer;
        }
        return (isEqual(currentSettings, originalSettings) && !disableHashCheck) || isSuperViewer;
    }, [
        isCloudConfig,
        currentSettings,
        originalSettings,
        disableHashCheck,
        isSuperViewer,
        currentCloudConfig,
        originalConfig,
    ]);

    const shouldRenderSettings =
        currentBhomeId &&
        ((!isCloudConfig && currentSettings?.data?.settings) || (isCloudConfig && currentCloudConfig?.config));

    return (
        <div className="config">
            <SelectField value={currentBhomeId} options={selectOptions} onChange={handleBhomeSelect} size="small" />
            {currentBhomeId && !currentSettings && !currentCloudConfig && isFetched && (
                <div className="config-no-settings">Selected Bhome doesn&#39;t have settings.</div>
            )}
            {shouldRenderSettings &&
                (isCloudConfig ? (
                    <BhomeCloudConfig
                        currentCloudConfig={currentCloudConfig}
                        fooKey={fooKey}
                        handleSaveSettings={handleSaveSettings}
                        handleSettingsChange={handleSettingsChange}
                        isSubmitDisabled={isSubmitDisabled}
                        isTextMode={isTextMode}
                        toggleMode={handleToggleMode}
                        handleDownload={handleDownload}
                        isCloudConfig={isCloudConfig}
                        remoteCloudConfig={remoteBhomeCloudConfig}
                    />
                ) : (
                    <BhomeSettings
                        currentSettings={currentSettings}
                        currentCloudConfig={currentCloudConfig}
                        fooKey={fooKey}
                        handleSaveSettings={handleSaveSettings}
                        handleSettingsChange={handleSettingsChange}
                        isSubmitDisabled={isSubmitDisabled}
                        disableHashCheck={disableHashCheck}
                        toggleHashCheck={toggleHashCheck}
                        isTextMode={isTextMode}
                        toggleMode={handleToggleMode}
                        handleDownload={handleDownload}
                        enforceImmediateAction={enforceImmediateAction}
                        toggleEnforceImmediateAction={toggleEnforceImmediateAction}
                        isCloudConfig={isCloudConfig}
                        remoteCloudConfig={remoteBhomeCloudConfig}
                    />
                ))}
        </div>
    );
};

BhomeConfig.propTypes = {
    bhomes: PropTypes.arrayOf(PropTypes.shape()),
    isSuperViewer: PropTypes.bool,
    isCloudConfig: PropTypes.bool,
};

export default loading([FETCH_BHOME_SETTINGS.default])(BhomeConfig);
